import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import FunctionalComponentShipmentItems from '../../../FunctionalComponents/FunctionalComponentShipmentItems.js'
import OrganismSearchOrAddItem from '../../../Organisms/OrganismSearchOrAddItem.js'
import OrganismAddItemDetails from '../../../Organisms/OrganismAddItemDetails.js'
import NavigationAddItems from '../../../PageComponents/Navigation/Quote/NavigationAddItems.js'
import OrganismAddItemSummary from '../../../Organisms/OrganismAddItemSummary.js'
import ModalAddItem from '../../../FunctionalComponents/Modal/ModalAddItem.js'

import AddReferenceNumber from '../../../FunctionalComponents/Add/ReferenceNumber/ReferenceNumberModule.js'

const GContentAddItems = styled.div`
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
  margin: 0 auto;
  max-width: 1360px;
  background-color: #fff;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: minmax(0.1fr, 1fr) 1fr 0.1fr;
  grid-template-columns: 1fr 0.35fr;
  grid-template-areas:
    "sg_add_items sg_summary"
    "sg_navigation_stepper sg_summary";

  .SGAddToShipment {
    grid-area: sg_add_items; // 🗺
  }
  .SGSummary {
    grid-area: sg_summary; // 🗺
  }
  .SGNavigationStepper {
    grid-area: sg_navigation_stepper; // 🗺
    background: none !important;
    align-self: start;
  }
`;

const SGAddToShipment = styled.div`
    padding-right: 10px;
    border-right: 2px solid #f8f8f8;
    margin-top: 20px;

    .es-sg-saved-item {
      .wrapper {
      margin-top: 30px;
      padding-bottom: 32px;
      /* border-bottom: 1px solid #bbbbbb; */
      max-width: 995px;
      // 📰
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 500px 0.1fr 0.2fr;
      grid-template-areas:
        "sg_col_1 sg_col_2 sg_col_3";
        
        .sg-col-1 {
          grid-area: sg_col_1; // 🗺
          align-self: center;
          padding-right: 30px;
        }
        .sg-col-2 {
          grid-area: sg_col_2; // 🗺
          justify-self: start;
          align-self: center;
        }
        .sg-col-3 {
          grid-area: sg_col_3; // 🗺
          justify-self: end;
          align-self: center;
          margin-left: 15px;
        }
    }
      }

      hr {
        border-top: 1px solid #e7edf0;
      }
`;
const SGSummary = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
`;
const SGNavigationStepper = styled.div`
`;
const GComponentPageTitle = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;  
`;
const PageTitle = styled.div`
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    margin: 0 auto;
    max-width: 1360px;
`;

const SGReferenceNumber = styled.div`
  // 📰
  display: grid;
  grid-template-rows: minmax(0.1fr, 1fr) 1fr 0.1fr;
  /* grid-template-columns: 1fr 0.35fr;
  grid-template-areas:
  "sg_add_items sg_summary"; */
`;
const ReferenceNumberTitle = styled.div`
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    max-width: 1360px;
`;


const GridComponentAddItems = ({children}) => {
  return (

    <GContentAddItems>

        <SGAddToShipment>

          <GComponentPageTitle>
            <PageTitle>Add Items to Shipment</PageTitle>
          </GComponentPageTitle>
          
          <FunctionalComponentShipmentItems></FunctionalComponentShipmentItems>

          <div id="newItem" style={{display: 'none'}}>
            <br/><br/>
            <FunctionalComponentShipmentItems></FunctionalComponentShipmentItems>
          </div>

          <div className='searchOrAddItem'>
            {/*<OrganismSearchOrAddItem></OrganismSearchOrAddItem>*/}
            <div className="es-sg-saved-item">
              <div className="wrapper">
                <div className="sg-col-1">
                  <div className="form-group es-i-total-shipment-value" style={{width: "100%"}}>
                    <input
                      className="form-input input-lg"
                      type="text"
                      id="input-total-shipment-value"
                      placeholder="Search for a saved item..."
                      onClick={showNewItem.bind(this)}
                    />
                  </div>
                </div>
                <div className="sg-col-2">OR</div>
                <div className="sg-col-3">
                  <ModalAddItem>
                    {children}
                  </ModalAddItem>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <OrganismAddItemDetails></OrganismAddItemDetails>

          <SGReferenceNumber>
            <ReferenceNumberTitle>Reference Numbers</ReferenceNumberTitle>

            <AddReferenceNumber>
              {children}
            </AddReferenceNumber>

          </SGReferenceNumber>

        </SGAddToShipment>

        <SGSummary>
          <OrganismAddItemSummary></OrganismAddItemSummary>
        </SGSummary>

        <SGNavigationStepper>
          <NavigationAddItems></NavigationAddItems>
        </SGNavigationStepper>



    </GContentAddItems>

  )
}

function showNewItem(){
  document.getElementById('newItem').style.display = 'block';
  document.activeElement.blur();
}

export default GridComponentAddItems;